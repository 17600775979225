import {Component, ViewChild} from '@angular/core';
import {Camada} from "@sema-geo/sema-geoportal";
import {GeometriaMapa} from "@sema-geo/sema-geoportal";
import {MapaPadraoComponent} from "@sema-geo/sema-geoportal";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {

    @ViewChild("mapaPadraoComponent", {static: false})
    mapaPadraoComponent: MapaPadraoComponent;

    atual = 1;

    camadaTrabalho: Camada = {
        id: 0,
        title: 'Camada de trabalho',
        name: 'trabalho',
        geometryGroup: true,
        expandGroup: true,
        camadas: [],
        permissao: {
            ponto: true,
            linha: true,
            poligono: true,
            buffer: true,
            exportar: true,
            remover: true,
            upload: true
        }
    };

    camadas: Camada[] = [this.camadaTrabalho];

    camadaSelecionada: Camada = this.camadaTrabalho;

    onAddFeature(geometria: GeometriaMapa): void {
        geometria.id = this.atual++;
        geometria.title = geometria.tipoGeometria;
        geometria.permissao = {
            remover: true
        };
        geometria.feature.setStyle(this.mapaPadraoComponent.defaultStyle)
        this.camadaSelecionada.camadas.push(geometria);
        this.camadas = [...this.camadas];
    }

    onRemoveFeature(geometria: GeometriaMapa): void {
        const index = this.camadaTrabalho.camadas.findIndex(c => {
            const g = c as GeometriaMapa;
            return g.feature === geometria.feature;
        });
        this.camadaSelecionada.camadas.splice(index, 1);
        this.camadas = [...this.camadas];

        this.mapaPadraoComponent.deleteFeature(geometria);
    }

    onLimpar(): void {
        this.camadaSelecionada.camadas = [];
        this.camadas = [...this.camadas];
        this.mapaPadraoComponent.deleteAllFeatures();
    }

    select(camadaSelecionada: Camada): void {
        this.camadaSelecionada = camadaSelecionada;
    }

    onImportar(file: File): void {
        this.mapaPadraoComponent.importarFile(file);
    }
}
